<!-- SINGLE DISPLAY USE CASES -->
<div *ngIf="isSimpleData && !withTitleLabel">
  <div class="html-overflow">
    {{ workObjectDOM?.title ? workObjectDOM?.title : "-" }}
  </div>
</div>

<div *ngIf="isSimpleData && withTitleLabel">
  <div class="w-h-label html-overflow">
    {{ titleLabel | translate }}
  </div>
  <div class="html-overflow">
    {{ workObjectDOM?.title ? workObjectDOM?.title : "-" }}
  </div>
</div>

<!-- WORK OBJECT DETAIL VIEW -->
<div *ngIf="isWorkObjectDetail">
  <div class="work-object-static-data-grid">
    <span style="max-width: 250px">
      <ng-bee-customer-static-data
        [withLabel]="true"
        [detailURL]="workObjectDOM?.customerDetailURL"
      >
      </ng-bee-customer-static-data>
    </span>

    <span *ngIf="isLocationEnabled && isCase">
      <ng-bee-w-h-agm-core-location-formatted-address
        *ngIf="isLocationEnabled"
        [withLabel]="true"
        [locationDTO]="location"
      >
      </ng-bee-w-h-agm-core-location-formatted-address>
    </span>

    <span *ngIf="isWorkObjectLocationEnabled && (isStandingOrder || isProject)">
      <ng-bee-w-h-agm-core-location-formatted-address
        *ngIf="isWorkObjectLocationEnabled"
        [withLabel]="true"
        [locationDTO]="workObjectDOM?.location"
      >
      </ng-bee-w-h-agm-core-location-formatted-address>
    </span>

    <span *ngIf="isStandingOrder" style="max-width: 250px">
      <div class="w-h-label html-overflow">
        {{ "standingorder.model.accountinginfo" | translate }}
      </div>
      <div class="html-overflow">
        {{
          workObjectDOM?.accountingInfo ? workObjectDOM?.accountingInfo : "-"
        }}
      </div>
    </span>

    <span *ngIf="isCase">
      <div class="w-h-label html-overflow">
        {{ "case.model.sla" | translate }}
      </div>
      <div class="html-overflow">
        {{
          (SLA[workObjectDOM?.sla] ? SLA[workObjectDOM?.sla] : "-") | titlecase
        }}
      </div>
    </span>

    <span *ngIf="isCase && CATEGORY && CATEGORY[workObjectDOM?.category]">
      <div class="w-h-label html-overflow">
        {{ "workorder.model.category" | translate }}
      </div>
      <div class="html-overflow">
        {{
          CATEGORY[workObjectDOM?.category]
            ? CATEGORY[workObjectDOM?.category]
            : "-"
        }}
      </div>
    </span>

    <span *ngIf="isCase">
      <div class="w-h-label html-overflow">
        {{ "case.model.priority" | translate }}
      </div>
      <div class="html-overflow">
        {{
          (PRIORITY[workObjectDOM?.priority]
            ? PRIORITY[workObjectDOM?.priority]
            : "-"
          ) | titlecase
        }}
      </div>
    </span>

    <span>
      <div class="w-h-label workobject-desc html-overflow">
        {{ "project.model.priorityscore" | translate }}
      </div>
      <p>
        {{ workObjectDOM?.priorityScore ? workObjectDOM?.priorityScore : "-" }}
      </p>
    </span>

    <span *ngIf="isCase">
      <div class="w-h-label workobject-desc html-overflow">
        {{ "case.model.category" | translate }}
      </div>
      <p>
        {{
          WORK_OBJECT_CATEGORY[workObjectDOM?.workObjectCategory]
            ? WORK_OBJECT_CATEGORY[workObjectDOM?.workObjectCategory]
            : "-"
        }}
      </p>
    </span>

    <span *ngIf="isStandingOrder || isProject">
      <div class="w-h-label workobject-desc html-overflow">
        {{ "case.model.category" | translate }}
      </div>
      <p>
        {{
          WORK_OBJECT_CATEGORY[workObjectDOM?.category]
            ? WORK_OBJECT_CATEGORY[workObjectDOM?.category]
            : "-"
        }}
      </p>
    </span>

    <span *ngIf="isCase">
      <ng-bee-w-h-date-static-data
        [withTimeFrameLabel]="true"
        [isWithStartDateLabel]="false"
        [isWithEndDateLabel]="false"
        [isSingleDate]="true"
        [isStartTimeVisible]="true"
        [timeFrameLabel]="'case.ui.timeframe.label'"
        [startDateLabel]="'case.model.occurrencedate'"
        [startDate]="workObjectDOM?.occurrenceDate"
        [endDateLabel]="'workobject.model.scheduledend'"
        [endDate]="workObjectDOM?.scheduledEndDate"
      >
      </ng-bee-w-h-date-static-data>
    </span>

    <span *ngIf="isProject">
      <ng-bee-w-h-date-static-data
        [withTimeFrameLabel]="true"
        [isWithStartDateLabel]="true"
        [isWithEndDateLabel]="true"
        [timeFrameLabel]="'project.ui.timeframe.label'"
        [startDateLabel]="'project.model.start'"
        [startDate]="workObjectDOM?.startDate"
        [isStartTimeVisible]="isStartTimeVisible"
        [endDateLabel]="'project.model.end'"
        [endDate]="workObjectDOM?.endDate"
        [isEndTimeVisible]="isEndTimeVisible"
      >
      </ng-bee-w-h-date-static-data>
    </span>

    <span *ngIf="isCase">
      <div class="w-h-label html-overflow">
        {{ "case.model.callerperson" | translate }}
      </div>
      <div class="html-overflow">
        {{ workObjectDOM?.callerPerson ? workObjectDOM?.callerPerson : "-" }}
      </div>
    </span>

    <span *ngIf="isStandingOrder">
      <ng-bee-w-h-date-static-data
        [withTimeFrameLabel]="true"
        [isWithStartDateLabel]="true"
        [isWithEndDateLabel]="true"
        [timeFrameLabel]="'standingorder.ui.timeframe.label'"
        [startDateLabel]="'standingorder.model.validfrom'"
        [startDate]="workObjectDOM?.validFrom"
        [endDateLabel]="'standingorder.model.validto'"
        [endDate]="workObjectDOM?.validTo"
      >
      </ng-bee-w-h-date-static-data>
    </span>

    <span *ngIf="isCase">
      <div class="w-h-label html-overflow">
        {{ "case.model.callerphone" | translate }}
      </div>
      <div class="html-overflow">
        {{
          workObjectDOM?.callerPhone
            ? workObjectDOM?.callerPhone
            : ("-" | titlecase)
        }}
      </div>
    </span>

    <span *ngIf="isTargetStateEnabled">
      <div class="w-h-label workobject-desc html-overflow">
        {{ "ui.model.targetState" | translate }}
      </div>
      <p>
        {{
          WORK_OBJECT_TARGET_STATE[workObjectDOM?.targetState]
            ? WORK_OBJECT_TARGET_STATE[workObjectDOM?.targetState]
            : "-"
        }}
      </p>
    </span>

    <span *ngIf="isPlannedWorkEnabled">
      <div class="w-h-label workobject-desc html-overflow">
        {{ "workorder.sumtargettime.label" | translate }}
      </div>
      <p>{{ sumOfPlannedWorkTime }} min</p>
    </span>

    <span *ngIf="isProject">
      <mat-slide-toggle
        style="padding-left: 10px"
        [disabled]="true"
        [checked]="workObjectDOM?.sendTechnicianReminder"
      >
        {{ "workobject.ui.remindertoggle.label" | translate }}
      </mat-slide-toggle>
      <i
        class="workheld-icons w-h-info-icon"
        [innerHTML]="infoIcon"
        [matTooltip]="'workobject.ui.remindertoggle.helpertext' | translate"
      ></i>
    </span>

    <span *ngIf="workObjectDOM?.executionGroupTitle" class="p-b--12">
      <div class="w-h-label workobject-desc html-overflow">
        {{ "app.ui.executiongroup.label" | translate }}
      </div>
      <app-w-h-chip-list
        (click)="openExecutionGroupDetails()"
        [data]="[{ executionGroupTitle: workObjectDOM?.executionGroupTitle }]"
        [tooltipKeys]="['executionGroupTitle']"
        [isStacked]="false"
        [titleKey]="'executionGroupTitle'"
      ></app-w-h-chip-list>
    </span>
  </div>

  <span class="w-100">
    <div class="w-h-label workobject-desc html-overflow">
      {{ "workobject.model.description" | translate }}
    </div>
    <div style="min-height: 50px" class="p-r--12">
      <mat-form-field class="w-100">
        <textarea
          [disabled]="true"
          rows="2"
          matInput
          [value]="workObjectDOM?.description ? workObjectDOM.description : ''"
        >
        </textarea>
      </mat-form-field>
    </div>
  </span>
  <span class="w-100">
    <div class="w-h-label html-overflow">
      {{ "workobject.model.contacts" | translate }}
    </div>

    <div class="flex--r-fs flex--wrap gap--12 p-r--12">
      <w-h-contact-card
        *ngFor="let contact of workObjectDOM?.contacts"
        class="flex-1"
        [contactDTO]="contact"
        (openModal)="contactCrud($event)"
      ></w-h-contact-card>

      @if (workObjectDOM?.contacts?.length < 2) {
        <button mat-stroked-button color="accent" (click)="contactCrud()">
          {{ "contact.ui.add.label" | translate }}
        </button>
      }
    </div>
  </span>
</div>
